/* GalleryPreview.module.css */

/* Container for the gallery */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Gallery heading */
.heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
}

/* Define grid layout */
.galleryGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default to 1 column */
  gap: 1.25rem; /* Space between items */
  width: 100%;
}

/* Responsive grid: 2 columns on medium screens */
@media (min-width: 768px) {
  .galleryGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Responsive grid: 4 columns on large screens */
@media (min-width: 1024px) {
  .galleryGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Image styling */
.galleryImg {
  width: 100%;
  height: 250px; /* Fixed height */
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.galleryImg:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Button container */
.buttonContainer {
  text-align: center;
  margin-top: 2rem;
}

/* View More button styling */
.button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.button:hover {
  background-color: #0056b3;
}
